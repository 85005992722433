import React from "react";
import { Col, Row } from "react-bootstrap";
import { CgCPlusPlus } from "react-icons/cg";
import {
  DiJavascript1,
  DiReact,
  DiNodejs,
  DiMongodb,
  DiPython,
  DiGit,
  DiBootstrap,
  DiHtml5,
  DiHtml53DEffects,
  DiNetmagazine,
} from "react-icons/di";
import { SiPytorch, SiTensorflow, SiFirebase, SiUnity, SiAFrame, SiAdobeaftereffects } from "react-icons/si";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      
      <Col xs={4} md={2} className="tech-icons">
        <DiJavascript1 />
      </Col>
          <Col xs={4} md={2} className="tech-icons">
              <DiPython />
          </Col>
          <Col xs={4} md={2} className="tech-icons">
              <DiNetmagazine />
          </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiReact />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiMongodb />
      </Col>
      
          <Col xs={4} md={2} className="tech-icons">
              <SiAdobeaftereffects />
          </Col>

          <Col xs={4} md={2} className="tech-icons">
              <SiUnity />
          </Col>
    </Row>
  );
}

export default Techstack;
