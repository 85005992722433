import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import appanalyst from "../../Assets/Projects/AI.png";
import blog from "../../Assets/Projects/game.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>

                  <Col md={4} className="project-card">
                      <ProjectCard
                          imgPath={appanalyst}
                          isBlog={false}
                          title="Ad analyst"
                          description="AI powered cloud app  that block ads without any installation required throught DNS  there's also a plugin for browsers and apk to prevent userdata going to unwanted pepole   "
                          link="https://github.com/prestigedevop"
                      />
                  </Col>
                  <Col md={4} className="project-card">
                      <ProjectCard
                          imgPath={blog}
              isBlog={true}
              title="Game"
              description="coming soon..  "
              link="https://github.com/prestigedevop"
            />
          </Col>

          
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
